<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WithJob',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isJobLoading: false,
      jobError: null,
      placeholderJob: {},
    }
  },
  computed: {
    ...mapGetters(['getJobFromId']),
    job() {
      return this.getJobFromId(this.id) || this.placeholderJob
    },
    metaFields() {
      if (this.isJobLoading || this.jobError) return []
      return [
        {
          title: 'Job Number',
          value: this.job.JOB_NUMBER,
        },
        {
          title: 'Company',
          value: this.job.COMPANY[0]?.identifier,
        },
        {
          title: 'Job Status',
          value: this.job.STATUS,
        },
        {
          title: 'Estimated Billings',
          value: '$' + this.job.ESTIMATED_BILLINGS,
        },
        {
          title: 'Project Start of Work',
          value: this.job.PROJECT_START_OF_WORK,
        },
      ]
    },
  },
  watch: {
    id: {
      async handler(id) {
        this.isJobLoading = true
        try {
          await this.fetchJob({ ID: id })
        } catch (error) {
          this.jobError = true
          throw Promise.reject(error)
        } finally {
          this.isJobLoading = false
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['fetchJob']),
  },
  render() {
    const { job, isJobLoading, jobError, metaFields } = this
    return this.$scopedSlots.default({
      job,
      isJobLoading,
      jobError,
      metaFields,
    })
  },
}
</script>
