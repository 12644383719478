<template>
  <Layout>
    <WithJob :id="jobId">
      <div slot-scope="{ job, isJobLoading, metaFields }">
        <BaseSpinner v-if="isJobLoading" />
        <template v-else>
          <VContainer fluid py-1 class="text-xs-left my-1">
            <BaseButton
              to="/jobs"
              class="ml-0"
              outline
              color="white"
              md-icon-position="left"
              md-icon="chevron_left"
              >Back to All Jobs</BaseButton
            >
            <VCard class="mt-2 pa-3 elevation-0">
              <VLayout justify-space-between wrap>
                <VFlex
                  v-for="field in metaFields"
                  :key="field.title"
                  grow
                  xs12
                  class="text-xs-left meta-field"
                  ><span class="font-weight-bold">{{ field.title }}</span
                  >: {{ field.value || '-' }}</VFlex
                >
              </VLayout>
            </VCard>
          </VContainer>
          <VContainer fluid py-1 my-3>
            <JobAssetManager :job="job" />
          </VContainer>
        </template>
      </div>
    </WithJob>
  </Layout>
</template>

<script>
import * as appConfig from '@src/app.config'
import Layout from '@layouts/breadcrumbLayout'
import WithJob from '@dataProviders/WithJob'
import JobAssetManager from '@components/DailyReportView/JobAssetManager'

import { HumanFields } from '@constants/knack'

import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapState: routeState } = createNamespacedHelpers('route')

export default {
  name: 'JobView',
  components: {
    Layout,
    WithJob,
    JobAssetManager,
  },
  metaInfo() {
    let title = this.getJobFromId(this.jobId)?.JOB_NAME
    return {
      title,
      meta: [{ name: 'description', content: appConfig.description }],
    }
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getJobFromId']),
    ...routeState(['query']),
    filterByJob() {
      return [
        {
          field: HumanFields.DAILY_REPORT.JOB,
          operator: 'is',
          value: this.jobId,
        },
      ]
    }, // filterByJob
  },
  methods: {
    nextPage() {
      let pageNum = +this.query.page ? +this.query.page + 1 : 2
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // nextPage
    previousPage() {
      let pageNum = +this.query.page
      pageNum = pageNum && pageNum > 1 ? pageNum - 1 : 1
      this.$router.push({
        query: { ...this.$route.query, page: pageNum },
      })
    }, // previousPage
  },
}
</script>

<style lang="scss" scoped>
.meta-field {
  font-size: 1.5rem;
}
</style>
